import axios from '@/libs/axios'

class DataService {
  getAll() {
    return axios.get("/coupons");
  }
  get(id) {
    return axios.get(`/coupons/${id}`);
  }
  create(data) {
    return axios.post("/coupons", data);
  }
  update(id, data) {
    return axios.put(`/coupons/${id}`, data);
  }
  delete(id) {
    return axios.delete(`/coupons/${id}`);
  }
  deleteAll() {
    return axios.delete(`/coupons`);
  }
  findByTitle(title) {
    return axios.get(`/coupons?title=${title}`);
  }
  changeStatus(id) {
    return axios.get(`changecouponsStatus/${id}`);
  }
}
export default new DataService();